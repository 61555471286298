import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {ApirequestService} from "./services/apirequest.service";
import {NotificationService} from "./services/notification.service";
import {HttpClient} from "@angular/common/http";
import {Meta, Title} from "@angular/platform-browser";
import {ApiParametersModel} from "./models/api-parameters.model";

@Injectable({
  providedIn: "root"
})
export class CommonServices {

  //default settings
  siteSettings = {
    project_name: "OoWsome",
    header_color: "#2A3042",
    footer_color: "#F2F2F5",
    footer_text: "2024 © OoWsome.",
    login_page_background: "https://partners.oowsome.com/resources/bg.jpg",
    logo: "",
    favicon: "",
    font_family: 'bree_serif',
    website_color: "#556ee6",
    login_page_text: 'Enter details below to Login',
    site_title: 'OoWsome Performance AdNetwork - Best Affiliate Network - Best CPA Network',
    meta_description: 'OoWsome is one of the Leading & reputed Affiliate CPA Network, Become a publisher of OoWsome & start earning massive income from your web or mobile traffic starting driving traffic to our exclusive offers today',
    meta_keywords: 'Affiliate network, Affilaites,publishers, cpa ads, campaigns, Best CPA Network',
    card_title_font_color: '#333333',
    card_title_font_size: 15,
    card_title_font_family: 'bree_serif',
  };

  constructor(
    private apiService: ApirequestService,
    private notificationService: NotificationService,
    private httpClient: HttpClient,
    private meta: Meta,
    private title: Title,
  ) {
    this.siteSettings.logo = environment.apiServer + '../resources/assets/logo-light.png';
    this.siteSettings.favicon = environment.apiServer + '../assets/favicon.ico'
  }

  addBodyStyling() {
    let mainBodyStyle = document.getElementById("main-body-style") as HTMLElement;
    if (mainBodyStyle != null) {
      let bodyElement = document.getElementsByTagName("body")[0] as HTMLElement;
      if(bodyElement!=null){
        bodyElement.setAttribute("data-font", this.siteSettings.font_family);
        bodyElement.setAttribute("data-card-title-font", this.siteSettings.card_title_font_family);
      }
      mainBodyStyle.innerHTML = "<style>" +
        ":root{--primaryColor: " + this.siteSettings.website_color + ";}" +
        "body[data-topbar=dark] #page-topbar {background: " + this.siteSettings.header_color + ";}" +
        "footer.footer {background: " + this.siteSettings.footer_color + ";}" +
        ".card-title {color: " + this.siteSettings.card_title_font_color + " !important; font-size: " + this.siteSettings.card_title_font_size + "px !important;}" +
        "body.not-logged-in{background-image: url('" + this.siteSettings.login_page_background + "') !important;}" +
        "</style>";
    }
  }

  updateMetaTags(){
    this.title.setTitle(this.siteSettings.site_title);
    this.meta.updateTag({ name: 'description', content: this.siteSettings.meta_description });
    this.meta.updateTag({ name: 'keywords', content: this.siteSettings.meta_keywords });
  }

  updateSiteSettings() {
    this.getSiteSettings().subscribe(res => {
        // @ts-ignore
        this.siteSettings = res;
        this.addBodyStyling();
        this.updateMetaTags();
      },
      error => {
        this.notificationService.error('Failed to load some setting, site may not work as expected.');
      });
  }

  getSiteSettings() {
    return this.httpClient.get("user/get-site-settings")
  }

  updateAsset(id, value) {
    return this.apiService.post("admin-users/update-asset", {
      id: id,
      value: value
    })
  }

  getLogo(): string {
    return this.siteSettings.logo;
    //return environment.apiServer + '../resources/assets/logo-main.png';
  }

  getLogoLight(): string {
    return this.siteSettings.logo;
  }

  getFavicon(): string {
    return this.siteSettings.favicon;
  }

  getFooterText(): string {
    return this.siteSettings.footer_text;
  }

  getStringInitials(string): string {
    if (string != '') {
      var initials = string.match(/\b\w/g) || [];
      initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
      return initials;
    }
    return '?';
  }

  getSortColumn(newColumn: string, oldColumn: string): string {
    if (oldColumn === '') {
      return newColumn;
    } else if (oldColumn !== newColumn) {
      return newColumn;
    } else {
      const firstChar = oldColumn.charAt(0);
      if (firstChar !== '-') {
        return '-' + newColumn;
      } else {
        return newColumn;
      }
    }
  }

  objectToQueryString(obj: object): string {
    const str = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }
    return str.join('&');
  }

  buildQuery(options, per_page, page_number): string {
    if (options == null) {
      options = new ApiParametersModel();
    }
    if (per_page == null) {
      per_page = environment.itemsPerPage;
    }
    let queryString = this.objectToQueryString(options.params);
    if (queryString !== '') {
      queryString = '?' + queryString + '&';
    } else {
      queryString = '?';
    }

    let sortParameterKey = 'sort';
    if (options.sortParam && options.sortParam !== '') {
      sortParameterKey = options.sortParam;
    }
    let extra_query = queryString + 'per-page=' + per_page + '&page=' + page_number;

    let sortColumn = '-id';
    if (options.sort && options.sort !== '') {
      sortColumn = options.sort;
    }

    extra_query += '&' + sortParameterKey + '=' + sortColumn;

    if (options.expand && options.expand !== '') {
      extra_query += '&expand=' + options.expand;
    }
    return extra_query;
  }

}
